import {useState} from 'react';
import {Form} from 'react-final-form';
import './App.css';
import {TextField} from './TextField';

const INITIAL_VALUES = {
  saved: 600000,
  monthlyExpenses: 5000,
  interest: 0.03,
  inflation: 0.05,
};

const STORED_INITIAL_VALUES =
  JSON.parse(window.localStorage.getItem('values')) || INITIAL_VALUES;

function App() {
  const [totals, setTotals] = useState([]);

  const handleSubmit = (values) => {
    const saved = parseFloat(values.saved);
    const monthlyExpenses = parseFloat(values.monthlyExpenses);
    const interest = parseFloat(values.interest);
    const inflation = parseFloat(values.inflation);

    console.log({
      saved,
      monthlyExpenses,
      interest,
      inflation,
    });

    let remaining = saved;
    let yearlyExpenses = monthlyExpenses * 12;

    const totals = [];

    while (remaining > 0) {
      let yearlyReturns = Math.max((remaining - yearlyExpenses) * interest, 0);

      const yearlyRemaining = remaining + yearlyReturns - yearlyExpenses;

      remaining = yearlyRemaining;
      totals.push({yearlyRemaining, yearlyExpenses, yearlyReturns});

      yearlyExpenses *= 1 + inflation;
    }

    setTotals(totals);
    window.localStorage.setItem('values', JSON.stringify(values));
  };

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <Form
        initialValues={STORED_INITIAL_VALUES}
        onSubmit={handleSubmit}
        render={({handleSubmit, values}) => (
          <div style={{margin: 'auto'}}>
            <div style={{display: 'flex'}}>
              <TextField name="saved" label="Saved" />

              <TextField name="interest" label="Interest" />
            </div>

            <div style={{display: 'flex'}}>
              <TextField name="monthlyExpenses" label="Monthly Expenses" />

              <TextField name="inflation" label="Inflation" />
            </div>

            <button
              type="submit"
              onClick={handleSubmit}
              style={{display: 'block', margin: 'auto', marginTop: 20}}
            >
              Calculate
            </button>
          </div>
        )}
      />

      {totals.length > 0 && (
        <table>
          <thead>
            <tr>
              <th></th>
              <th style={{textAlign: 'left'}}>Yearly expenses</th>
              <th style={{textAlign: 'left'}}>Yearly returns</th>
              <th style={{textAlign: 'left'}}>Remaining at end of year</th>
            </tr>
          </thead>

          <tbody>
            {totals.map((t, i) => (
              <tr key={`${t.yearlyRemaining}`}>
                <td>Year {i + 1}</td>

                <td>{Math.round(t.yearlyExpenses).toLocaleString()}</td>

                <td>{Math.round(t.yearlyReturns).toLocaleString()}</td>

                <td>{Math.round(t.yearlyRemaining).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default App;
