// import {InputHTMLAttributes} from 'react';
import {useField} from 'react-final-form';
// import cx from 'classnames';

// interface TextInputProps
//   extends InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
//   label?: string;
//   error?: string;
//   textarea?: boolean;
//   className?: string;
// }

export function TextInput({label, error, textarea, className, ...rest}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        margin: '10px 20px',
      }}
    >
      {!!label && <label style={{}}>{label}</label>}

      <input
        type="number"
        // className={cx(
        //   'w-full h-[50px] py-2 px-8 rounded-[15px]',
        //   'text-darkBrown placeholder-lightBrown',
        //   // 'text-sm md:text-base',
        //   'text-base',
        //   'outline-none',
        //   'border focus:border-lightBrown',
        //   {'border-error': error, 'border-[transparent]': !error}
        // )}
        {...rest}
      />

      {!!error && <p className="text-error mt-2 ml-8 text-sm">{error}</p>}
    </div>
  );
}

// interface TextFieldProps extends TextInputProps {
//   name: string;
// }

export function TextField({name, label, ...rest}) {
  const {input, meta} = useField(name, {...rest});

  const error =
    (meta.touched && meta.error) ||
    (!meta.dirtySinceLastSubmit && meta.submitError);

  return <TextInput label={label} error={error} {...input} {...rest} />;
}
